@charset "utf-8";
html{font-size: 13pt;}
.container{overflow: hidden; width: 100%; height: 100%; top:0; }
.nerd-bg{background: url(../images/gui/nerd_cat_bg.png) repeat 0 0; z-index: 1;}

/* button style */
.btn-set{text-align: center;}
.btn-set button{position: relative; text-align: center}

.btn{border: 1px solid #ffffff; background-color: #000000; color: #ffffff; border-radius: 10px; margin:10px 3px 10px 3px; padding: 10px 45px; font-size: 2.4rem; font-weight: 700; text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000; }
.btn-lg{font-size:1.8rem; padding: 12px 19px;}
.btn::after{content: ''; position: absolute; width: 100%; height: 65%; background: url(../images/gui/btn_light.png) no-repeat; background-size: 100% 130%; left: 0px; top: 0px;}
.btn-primary{background-color:#005d95;border-color: #009dff;}
.btn-red{background-color: #950000;border-color:#d51414;}
.btn-purple{background-color: #480095;border-color: #6e00de;}
.btn-yellow{background-color: #958100;border-color: #ffde00;}
.btn-yellow2{background-color: #ffde13;border-color: #ffb700;}
.btn-green{background-color: #0f9500;border-color: #1cff00;}

.btn-sm{font-size:0.8rem; padding: 3px 13px;margin:0;position: relative;border-radius: 4px;}
.btn-sm::after{width: 100%; height: 50%; background: url(../images/gui/btn_light.png) no-repeat; background-size: 100% 130%; left: 0px; top: 0px;}

.btn-outline-primary{color:#005d95;border-color:currentColor;}
.btn-outline-red{color: #950000;border-color:currentColor;}
.btn-outline-purple{color: #480095;border-color:currentColor;}
.btn-outline-yellow{color: #958100;border-color:currentColor;}
.btn-outline-yellow2{color: #ffb700;border-color:currentColor;}
.btn-outline-green{color: #0f9500;border-color:currentColor;}

.btn-ico1{padding-left:65px}
.btn-ico1::before{content: ''; position: absolute; width: 50px; height: 50px; left:3px; top:3px; background: url(../images/gui/btn-ico1.png) no-repeat 0 0; background-size: contain; }

.btn-ico2{padding-left:65px}
.btn-ico2::before{content: ''; position: absolute; width: 50px; height: 50px; left:3px; top:3px; background: url(../images/gui/btn-ico2.png) no-repeat 0 0; background-size: contain; }

.btn-ico3{padding-left:65px}
.btn-ico3::before{content: ''; position: absolute; width: 50px; height: 50px; left:3px; top:3px; background: url(../images/gui/Quest_21.png) no-repeat 0 0; background-size: contain; }

.btn-ico4{padding-left:65px}
.btn-ico4::before{content: ''; position: absolute; width: 50px; height: 50px; left:3px; top:3px; background: url(../images/gui/Herbalism_60_redrose.png) no-repeat 0 0; background-size: contain; }

.btn-set.dual button{position: absolute; width: calc(50% - 20px); font-size: 1.5rem; padding:15px 10px 13px 40px}
.btn-set.dual button:nth-child(1){left:10px}
.btn-set.dual button:nth-child(2){left:calc(50% + 10px)}

/* base layout */
.page-header{display:block; position: fixed; width: 100%; background-color: #121518; font-size: 1rem; color:#ffffff; border-bottom: 3px solid #61dafb; z-index: 9}
.page-header .app-header{margin:20px 0 10px 55px; padding-bottom: 2px;}
.page-header h1.app-header::before{content: ''; position: absolute; width: 35px; height: 35px; left:12px; top:15px; background: url(../images/gui/NerdFox.png) no-repeat 0 0; background-size: contain; }
.page-header .app-header a{color: #eeeeee;}
.page-header .app-header .user-info{float: right; height: 100%;}
.page-header .app-header .user-info img{width:25px; height:25px; border-radius: 50%; border:1px solid #eee;}
.page-header .app-header .user-info a{margin:0 5px 0 5px; font-size: 1.5rem;color:#f0d884}
.page-body{margin-top:63px; position: relative; height: calc(100% - 63px); overflow: hidden;}
.page-body .page-content,.page-body .page-content .content-body,.page-body .page-content .container{height: 100%; }

/* board */
.content-board{position: relative; font-size: 1.3rem; height: calc(100% - 80px); overflow: auto; overflow: auto;}
.content-board .board-row{position: relative; margin:8px 8px 2px 8px; padding:10px; background: #121518; border: 1px solid #1c2a39; border-radius: 7px;}
.content-board .board-row .time{position: absolute; top: 3px; right: 5px; color: #61dafb; font-size: 1.2rem}
.content-board .board-row .email{position: relative; top: -5px; left: 2px; color: #caeeaa; font-size: 1.3rem}
.content-board .board-row .content{position: relative; top: 4px; left: 2px; color: #acf2ff; font-size: 1.5rem}
.content-board .more{position: relative;text-align: center;opacity: 0}
.content-board .more button{position: relative;font-size: 1.2rem;width:65%;text-align: center}

/* popup */
.popup-group{position: fixed; width: 100%; height: 100%; top: 100%; left: 0; z-index: 10;}
.popup-group .popup{position: absolute; top:100%; left: 0; width: 100%; height:100%;font-size: 1.2rem;transition: all 0.5s ease;}
.popup-group .popup .popup-bg{position: absolute; width: 100%; height: 100%; background: url(../images/gui/op_bg_black.png) repeat 0 0; }
.popup-group .popup-container{position: relative;height:100%;width: 100%;}
.popup-group .popup-container .popup-content{position: relative;height:100%;width: 100%;}

/* about us */
.about-content{position: relative; width: calc(100% - 20px); height: 90%; top:calc(5% - 10px); left: 10px; background-color: #1c2a39; border-radius: 20px; border:0px solid #1c2a39;}
.about-content .title{text-align: center; font-size: 2rem; padding:10px 0 8px 0; border-bottom: 3px solid #ffdc00; border-radius:10px 10px 0 0; background-color: #af0000; text-shadow: -1px 0 #000, 0 3px #000, 3px 0 #000, 0 2px #000; }
.about-content .wrap{position: relative; width: calc(100% - 20px); height: calc(100% - 160px); left: 10px; top: 10px;}
.about-content .wrap .fox-what{position: relative; width: 100%; height: 95%; top: 0; left: 0;}
.about-content .wrap .fox-what .fox-sentense{position: absolute; width: 120%; padding:15px 10% 10px 10%; left: -10%; bottom: 35px; background: url(../images/gui/op_bg_black.png) repeat; font-size: 1.55rem;color: #e8e89c}
.about-content .wrap .fox-what img{margin-left: 10%; width: 80%}
.about-content .wrap .fox-about{font-size: 1rem; color:#1d9dd4; margin-top: 0px;}
.about-content .wrap .fox-about div{text-align: right}

/* board write */
.board-content{position: relative; width: calc(100% - 20px); height: 48%; top:calc(30% - 10px); left: 10px; background-color: #1c3936; border-radius: 20px; border:0px solid #1c2a39;}
.board-content .title{text-align: center; font-size: 2rem; padding:10px 0 8px 0; border-bottom: 3px solid #ffee00; border-radius:10px 10px 0 0; background-color: #93af00; text-shadow: -1px 0 #000, 0 3px #000, 3px 0 #000, 0 2px #000; }
.board-content .wrap{position: relative; width: calc(100% - 20px); height: calc(100% - 160px); left: 10px; top: 10px;}
.board-content .wrap .email input[type="email"] {width: 40%;padding: 10px;border: 1px solid #ccc;border-radius: 5px;margin-bottom: 15px;}
.board-content .wrap .email select {width: 42%;}
.board-content .wrap .email strong {font-size: 2.5rem;padding:3px 5px;vertical-align: middle}
.board-content .wrap .cont{position: relative; height: 80%}
.board-content .wrap .cont textarea.write-cont {width: 100%;height:calc(100% - 10px);padding: 10px;border: 1px solid #ccc;border-radius: 5px;margin-bottom: 15px;}

/* quest write */
.quest-content{position: relative; width: calc(100% - 20px); height: 48%; top:calc(30% - 10px); left: 10px; background-color: #1c3936; border-radius: 20px; border:0px solid #1c2a39;}
.quest-content .title{text-align: center; font-size: 2rem; padding:10px 0 8px 0; border-bottom: 3px solid #00fff6; border-radius:10px 10px 0 0; background-color: #00af2f; text-shadow: -1px 0 #000, 0 3px #000, 3px 0 #000, 0 2px #000; }
.quest-content .wrap{position: relative; width: calc(100% - 20px); height: calc(100% - 160px); left: 10px; top: 10px;}
.quest-content .wrap .email input[type="email"] {width: 40%;padding: 10px;border: 1px solid #ccc;border-radius: 5px;margin-bottom: 15px;}
.quest-content .wrap .email select {width: 42%;}
.quest-content .wrap .email strong {font-size: 2.5rem;padding:3px 5px;vertical-align: middle}
.quest-content .wrap .cont{position: relative; height: 80%}
.quest-content .wrap .cont textarea.write-cont {width: 100%;height:calc(100% - 10px);padding: 10px;border: 1px solid #ccc;border-radius: 5px;margin-bottom: 15px;}
/* 매체별 보정처리 */
@media screen and (max-height: 880px){
    html{font-size: 12px}
    .board-content .wrap{ height: calc(100% - 190px);}
    .quest-content .wrap{ height: calc(100% - 40px);}
    .btn-ico1::before{width: 40px; height: 40px; background-size: contain; }
    .btn-ico2::before{width: 40px; height: 40px; background-size: contain; }
    .btn-ico3::before{width: 40px; height: 40px; background-size: contain; }
    .btn-ico4::before{width: 40px; height: 40px; background-size: contain; }
}
/* 매체별 보정처리 */
@media screen and (max-height: 660px){
    html{font-size: 10px}
    .board-content .wrap{ height: calc(100% - 190px);}
    .quest-content .wrap{ height: calc(100% - 40px);}
    .btn-ico1::before{width: 40px; height: 40px; background-size: contain; }
    .btn-ico2::before{width: 40px; height: 40px; background-size: contain; }
    .btn-ico3::before{width: 40px; height: 40px; background-size: contain; }
    .btn-ico4::before{width: 40px; height: 40px; background-size: contain; }
}

/* gpt test */
.mt-20{margin-top: 20px;}
.full{height: 100%;}
.row > div{height: 100%;display: inline-block; vertical-align: top}
.row > div:nth-child(1){margin-right: 10px;}
.row > div .col-header {}
.row > div .col-header h2{font-size: 0.8rem;padding: 5px 0 5px 15px;display: inline-block}
.row > div .col-header .btnset{float: right}
.row > div .col-header .details-chk{font-size: 0.8rem;}
.row > div .col-header .details-chk input{width: 15px;height: 15px;}
.row > div .col-body{width: 100%;height: 50%;}
.row > div .col-body textarea{width: 100%;height: 100%;}
.row div.col-6{width: calc(50% - 20px);}
.row div.col-4{width: 33%;}
.row div.col-8{width: 66%;}
.row div .history .history-header{font-size: 0.9rem;margin-top:10px;}
.row div .history .history-header .res-time{margin:10px 10px 5px 10px; padding-top: 3px; font-size: 1.2rem; font-weight: 700; color:aquamarine; border-top: 1px solid #1d9dd4;}
.row div .history .history-header .res-token{margin:3px 10px;text-align: right}

