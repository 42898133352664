@charset "utf-8";

@keyframes _scaled_btn {
    0% {transform: translateX(-50%) scale(1);}
    50% {transform: translateX(-50%) scale(0.8);}
    100% {transform: translateX(-50%) scale(1);}
}
.scaled-btn:active {
    animation-name: _scaled_btn;
    animation-timing-function: linear;animation-fill-mode: backwards;animation-play-state: initial;animation-duration: 0.1s;
}
@keyframes scaled-el {
    0% {transform: scale(1);}
    50% {transform: scale(0.8);}
    100% {transform: scale(1);}
}
.scaled-el:active {
    animation-name: scaled-el;
    animation-timing-function: linear;animation-fill-mode: backwards;animation-play-state: initial;animation-duration: 0.1s;
}


@keyframes _tab_move1 {
    0% {transform: translateX(0%);}
    100% {transform: translateX(100%);}
}
@keyframes _tab_move2 {
    0% {transform: translateX(0%);}
    100% {transform: translateX(100%);}
}

.tab-move-attr{
    animation-timing-function: ease-in-out;animation-fill-mode: forwards;animation-play-state: initial;animation-duration: 0.5s;
}
.tab-move1{animation-name: _tab_move1;}
.tab-move2{animation-name: _tab_move2;}


@keyframes _list_row_intro1 {
    0% {transform: translateY(-100px); opacity:0;}
    100% {transform: translateY(0px); opacity:1;}
}
.list_row_intro1{opacity:0; animation-timing-function: ease-in-out;animation-fill-mode: forwards;animation-play-state: initial;animation-duration: 0.3s;animation-name: _list_row_intro1;}

@keyframes _popup_intro1 {
    0% { opacity:0;}
    10% { opacity:1;}
    100% { opacity:1;}
}
.popup_intro1{animation-timing-function: ease-in-out;animation-fill-mode: forwards;animation-play-state: initial;animation-duration: 2.8s;}

@keyframes -player-skull {
    0% {opacity:1;transform: scale(1) rotate(10deg);}
    50% {opacity:1;transform: scale(1.1) rotate(-10deg);}
    100% {opacity:1;transform: scale(1) rotate(10deg);}
}
.player-skull{animation-name: -player-skull;animation-timing-function: linear;animation-fill-mode: backwards;animation-play-state: initial;animation-iteration-count: infinite;animation-duration: 0.7s;}