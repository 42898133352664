@charset "utf-8";
html, body, ul,li{margin: 0; padding: 0}
html, body {font-size: 12px; width: 100%;height: 100%;overflow: hidden; font-size: xxx-large; color: #fff; background-color: #000; text-align: left }

#root,.page-container{ width: 100%; height: 100%; overflow: auto; text-align: left}

/* 공통 속성 */
html, body *{ text-align: left}

*,::after,::before{box-sizing:border-box;}
article,aside,figcaption,figure,footer,header,hgroup,main,nav,section{display:block;}
blockquote,body,button,dd,dl,dt,fieldset,form,h1,h2,h3,h4,h5,h6,input,legend,li,ol,p,pre,select,textarea,ul{margin:0;padding:0;}
img{display:inline-block;max-width:100%;vertical-align:middle;border:0;}
a{cursor:pointer;text-decoration:none;}
*:focus{outline:0;}
[hidden],.hidden{display:none;}
pre{white-space:pre-wrap;}
/* form element ---------------------------*/
button,input,select{-webkit-appearance:none;-moz-appearance:none;appearance:none;}
button,select{cursor:pointer;}
select::-ms-expand{display:none;}
textarea{overflow-y:auto;resize:vertical;}
textarea:-moz-read-only{resize:none;}
textarea:disabled,textarea:read-only{resize:none;}
.readonly,input:read-only,textarea:read-only{cursor:default;}
.disabled,button:disabled,input:disabled,select:disabled,textarea:disabled{cursor:default;pointer-events:none;}
button,input,select,textarea{font-family:inherit;}
/* list ul,ol ---------------------------*/
ol,ul{list-style:none;}
/* input clear ---------------------------*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-inner-spin-button,input::-webkit-outer-spin-button{margin:0;-webkit-appearance:none;}
/* clears the 'X' from Chrome */
input::-webkit-search-cancel-button,input::-webkit-search-decoration,input::-webkit-search-results-button,input::-webkit-search-results-decoration{display:none;}
/* Firefox */
input[type="number"]{-moz-appearance:textfield;}
/* clears the 'X' from Internet Explorer */
input::-ms-clear{display:none;width:0;height:0;}
input::-ms-reveal{display:none;width:0;height:0;}
/* placeholder ---------------------------*/
.placeholder,::placeholder{opacity:1; /* 파이어폭스에서 뿌옇게 나오는 현상을 방지하기 위한 css*/color:#999;}
::-webkit-input-placeholder{color:#999;}
:-moz-placeholder{color:#999;}
:-ms-input-placeholder{color:#999;}
/* scrollbar style(webkit 전용) */
::-webkit-scrollbar{width:5px;height:5px;}
::-webkit-scrollbar-track{background:transparent;}
::-webkit-scrollbar-thumb{background:#999;}
::-webkit-scrollbar-thumb:hover{background:#000;}
.mini::-webkit-scrollbar{width:2px;height:2px;}

/*select box*/
.select-box {
    padding: 8px 10px;
    border-radius: 5px; /* 둥근 모서리 */
    border: 1px solid #ccc; /* 테두리 색상 */
    background-color: white; /* 배경색 */
    outline: none; /* 기본 포커스 테두리 제거 */
    font-size: 16px; /* 글자 크기 */
    color: #333; /* 글자 색상 */
    -webkit-appearance: none; /* iOS 및 Safari 스타일 제거 */
    -moz-appearance: none; /* Firefox 스타일 제거 */
    appearance: none; /* 기본 브라우저 스타일 제거 */
    cursor: pointer; /* 커서 모양 변경 */
}

/* 화살표 스타일 커스텀 */
.select-box::-ms-expand {
    display: none;
}
